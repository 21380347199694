<template>
  <nav
    :style="`
    background-color:rgba(var(--nav-bar-bg-color), var(--nav-bar-bg-color), var(--nav-bar-bg-color), ${backgroundOpacity});
    box-shadow: rgba(0, 0, 0, ${shadowOpacity}) 0px 3px 6px, rgba(0, 0, 0, ${shadowOpacity}) 0px 3px 6px;
    `"
  >
    <router-link to="/" class="logo-router">
      <img src = "@/assets/tei_logo_small.svg" alt="TEI'25"/>
    </router-link>
    <span
      class="menu"
      :class="displayMenu ? '' : 'hide'">
      <router-link to="/" >Home</router-link>
            <DropdownComponent :class="route.path.match(/\/program\/*/g) ? 'active' : ''">
        <template v-slot:default>Program</template>
        <template v-slot:dropdown-list>
          <router-link to="/program/program">Program</router-link>
          <router-link to="/program/keynote_speaker">Keynote Speaker</router-link>
          <router-link to="/program/accepted_papers">Papers</router-link>
          <router-link to="/program/accepted_pictorials">Pictorials</router-link>
          <router-link to="/program/accepted_arts">Arts and Performance</router-link>
          <router-link to="/program/accepted_studio">Studio</router-link>
          <router-link to="/program/accepted_wip">Work In Progress</router-link>
          <router-link to="/program/accepted_gsc">Graduate Student Consortium</router-link>
          <router-link to="/program/accepted_sdc">Student Design Competition</router-link>
          <router-link to="/program/sustainability">Special Recognition for Sustainability</router-link>
        </template>
      </DropdownComponent>

      <DropdownComponent :isActive="route.path.match(/\/participate\/*/g)?.length === 1">
        <template v-slot:default>Participate</template>
        <template v-slot:dropdown-list>
          <router-link to="/participate/papers">Papers</router-link>
          <router-link to="/participate/pictorials">Pictorials</router-link>
          <router-link to="/participate/arts_and_performance">Arts and Performance</router-link>
          <router-link to="/participate/studio">Studio</router-link>
          <!--
          <router-link to="/participate/demo_arrangement">Demo</router-link>-->
          <router-link to="/participate/work_in_progress">Work In Progress</router-link>
          <router-link to="/participate/graduate_student_consortium">Graduate Student Consortium</router-link>
          <router-link to="/participate/student_design_competition">Student Design Competition</router-link>
          <router-link to="/participate/student_volunteers">Student Volunteers</router-link>
        </template>
      </DropdownComponent>
      <DropdownComponent :class="route.path.match(/\/attend\/*/g) ? 'active' : ''">
        <template v-slot:default>Attend</template>
        <template v-slot:dropdown-list>
          <router-link to="/attend/registration">Registration</router-link>
          <router-link to="/attend/venue">Venue</router-link>
          <router-link to="/attend/visainformation">Visa Information</router-link>
          <router-link to="/attend/accessibility">Accessibility FAQ</router-link>
        </template>
      </DropdownComponent>

      <DropdownComponent :isActive="route.path.match(/\/about\/*/g)?.length===1">
        <template v-slot:default>About</template>
        <template v-slot:dropdown-list>
          <router-link to="/about/about">About</router-link>
          <router-link to="/about/organizers">Organizers</router-link>
          <router-link to="/about/program-committee">Program Committee</router-link>
        </template>
      </DropdownComponent>
    </span>
    <span @click="displayMenu = !displayMenu" class="menu-hamb">
      <unicon name="bars" v-if="!displayMenu"/>
      <unicon name="times" v-else/>
    </span>
  </nav>
</template>

<script setup>
import DropdownComponent from './DropdownComponent.vue'
import {computed, onMounted, ref, watch} from 'vue'
import {useRouter, useRoute} from 'vue-router'

const displayMenu = ref(false)

const router = useRouter()
const route = useRoute()
const path = computed (() => {
  return route.path
})

const scrollPosition = ref (0)

onMounted(() => {
  window.addEventListener('scroll', () => {
    scrollPosition.value = window.scrollY
  });
})

const backgroundOpacity = computed(() => {
  if (route.path === '/') {
    return Math.min(1, scrollPosition.value/50)
  }
  return 1
})

const shadowOpacity = computed(() => {
  return 0.25 * Math.min(1, scrollPosition.value/50)
})

watch(path, () => {
  displayMenu.value=false
})
</script>

<style scoped>
* {
  font-weight: 600;
}

img {
  width: 100px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  color: var(--nav-text);
  z-index: 10000000;
}

nav.shadow {
  box-shadow: var(--shadow);
}

.menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  background-color: var(--background-alt);
  padding: 30px;
  padding-top: 70px;
  border-radius: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  z-index: 10000001;
}

.menu.hide {
  display: none;
}

.menu-hamb {
  margin-right: 10px;
  position: relative;
  top: 6px;
  z-index: 10000002;
}

@media (prefers-color-scheme: light) {
  img {
    filter: invert(1);
  }
}

@media (min-width: 1000px) {
  .menu.hide {
    display: flex;
  }

  .menu {
    display: flex;
    flex-direction: row;
    position: initial;
    background-color: transparent;
    padding: 10px;
    height: initial;
    overflow-y: visible;
  }

  .menu-hamb {
    display: none;
  }

  .menu > * {
    margin-right: 20px;
  }
}

@media (min-width: 1250px) {
  h1 {
    font-size: xx-large;
  }
}
</style>

<style>
nav .unicon {
  fill: var(--nav-text);
}

nav a, nav .dropbtn {
  color: var(--nav-text);
  font-size: larger;
  font-weight: 600;
  margin-top: 10px;
}

.logo-router img {
  width: 175px;
}

nav a:not(.logo-router), nav .dropbtn {
  width: 100%;
}

nav a.router-link-active, nav .active .dropbtn, nav .active .unicon {
  color: var(--accent);
  fill: var(--accent);
}

nav a:hover {
  color: var(--accent);
}

@media (min-width: 1000px) {
  nav a, nav .dropbtn {
    width: initial;
    margin-top: initial;
  }

  nav a:not(.logo-router), nav .dropbtn {
    width: initial;
  }
}
</style>
