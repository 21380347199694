<template>
  <div id="sponsor">
    <h2 class="sponsor-type">Partners</h2>
    <hr>
    <sponsor-component
        name="SIGCHI"
        img="sigchi"
        type="Partner"
        level=""
        websiteLink="https://sigchi.org"
        optionClass="invert"
    />
    <sponsor-component
        name="ACM"
        img="acm"
        type="Partner"
        level=""
        websiteLink="https://acm.org"
        optionClass="invert"
    />
    <sponsor-component
        name="Ville de Bordeaux"
        img="ville-de-bordeaux"
        type="Partner"
        level=""
        websiteLink="https://www.bordeaux.fr/"
    />
    <h2 class="sponsor-type next">Institutional</h2>
    <hr>
    <sponsor-component
        name="AFIHM"
        img="afihm"
        type="Sponsor"
        level="Silver"
        websiteLink="https://www.afihm.org/"
    />
    <sponsor-component
        name="Bordeaux INP"
        img="bordeaux-inp"
        type="Institutional"
        level=""
        optionClass="smaller"
        websiteLink="https://www.bordeaux-inp.fr/fr"
    />
    <sponsor-component
      name="ENSAD"
      img="EAD"
      type="Institutional"
      level=""
      optionClass="higher"
      websiteLink="https://www.ensad.fr/fr/universite-psl"
    />
    <sponsor-component
      name="LaBRI"
      img="LaBRI"
      type="Institutional"
      level=""
      optionClass="smaller"
      websiteLink="https://www.labri.fr/"
    />
    <h2 class="sponsor-type next">Sponsors</h2>
    <hr>
<!--    <h3 class="sponsor-level" >Silver</h3>-->
    <sponsor-component
        name="Force Dimension"
        img="force-dimension-1"
        type="Sponsor"
        level="Silver"
        websiteLink="https://www.forcedimension.com/"
    />
    <sponsor-component
        name="PEPR Ensemble"
        img="logo-pepr"
        type="Sponsor"
        level="Silver"
        websiteLink="https://pepr-ensemble.fr/"
    />
<!--    <h3>Other</h3>-->
<!--    <hr>-->
  </div>
</template>


<script>

import SponsorComponent from '@/components/SponsorComponent.vue'

export default {
  name: 'SponsorDivComponent',
  components: { SponsorComponent }
}
</script>

<style scoped>


img.sponsor {
  max-width: 80%;
  padding-bottom: 1rem;
}

#sponsor {
  text-align: center;
  border-radius: inherit;
  border-radius: 15px;
  background: var(--foreground);
  padding: 20px;
}

.sponsor-type.next{
  padding-top: 1.5em;
}

.sponsor-level{
padding-bottom: 0.5rem;
font-style:italic;
}

</style>
