<template>
  <main>
    <h1>Registration</h1>
    <p>
      We tried to make TEI 2025 registration as accessible as possible while still covering the expenses of the
      conference. Registration is now open through the CVENT online registration system: <a
        href="https://cvent.me/BqDEqa" target="_blank">https://cvent.me/BqDEqa</a>. Please note that for every accepted
      <strong>Paper</strong>, <strong>Pictorial</strong>, <strong>Arts</strong>, or <strong> Work-in-Progress</strong> submission, at
      least one author has to register personally.
    </p>
    <div style=" display: flex;
  justify-content: center;
  align-items: center;">
      <button class="buttonRegistration" role="button">
        <a href="https://cvent.me/BqDEqa" target="_blank" style="color: #000000;">Register here!</a></button>
    </div>
    <h2>Conference Registration & Fees
    </h2>
    <p>Registration to TEI 2025 will give you access to the live and interactive program. Full, in-person conference
      registration includes access to all sessions, coffee breaks, Tuesday Welcome reception, and conference dinner on
      Thursday where demos and art+performance session will take place. Online participation includes access to the live
      stream of the TEI’25 single track and provides access to a live-streamed tour of the demo and art & performance
      track session.</p>

    <!-- HTML !-->


    <table class="table_component" role="region" tabindex="0">
      <thead>
      <tr>
        <th>&nbsp;</th>
        <th style=" text-align: center;">IN-PERSON</th>
        <th style=" text-align: center;" colspan="2">ONLINE</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Content</td>
        <td>
          <ul>
            <li>All sessions</li>
            <li>Coffee breaks</li>
            <li>Welcome reception</li>
            <li>Conference dinner</li>
          </ul>
        </td>
        <td colspan="2">
          <ul>
            <li>Live stream of TEI'25</li>
            <li>Live tour of the demos and art track</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td rowspan="2">Price</td>
        <td rowspan="2">See the table below</td>
        <td>Authors</td>
        <td>€350</td>
      </tr>
      <tr>
        <td>Others</td>
        <td>€30</td>
      </tr>
      </tbody>
    </table>

    <div class="table_component" role="region" tabindex="0">
      <table id="t">
        <caption></caption>
        <thead>
        <tr>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <th style=" text-align: center;">IN-PERSON</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td rowspan="5">Early <s>(January 24th)</s> (February 1st)</td>
          <td>ACM/SIGCHI Members</td>
          <td>€600</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€700</td>
        </tr>
        <tr>
          <td>Student ACM/SIGCHI Members</td>
          <td>€350</td>
        </tr>
        <tr>
          <td>Student Non-Members</td>
          <td>€400</td>
        </tr>
        <tr class="thick-border">
          <td>Retiree</td>
          <td>€350</td>
        </tr>
        <tr>
          <td rowspan="5">Standard (February 12th)</td>
          <td>ACM/SIGCHI Members</td>
          <td>€700</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>Student ACM/SIGCHI Members</td>
          <td>€500</td>
        </tr>
        <tr>
          <td>Student Non-Members</td>
          <td>€550</td>
        </tr>
        <tr class="thick-border">
          <td>Retiree</td>
          <td>€600</td>
        </tr>
        <tr>
          <td rowspan="5">Late (March 5th)</td>
          <td>ACM/SIGCHI Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>Non-Members</td>
          <td>€800</td>
        </tr>
        <tr>
          <td>Student ACM/SIGCHI Members</td>
          <td>€550</td>
        </tr>
        <tr>
          <td>Student Non-Members</td>
          <td>€600</td>
        </tr>
        <tr>
          <td>Retiree</td>
          <td>€600</td>
        </tr>
        </tbody>
      </table>
    </div>
    <p>Potential attendee can also benefit from the <a href="https://sigchi.org/awards/gary-marsden-travel-awards/"
                                                       target="_blank">Gary Marsden Travel Awards</a> and <a
        href="https://sigchi.org/resources/sigchi-development-fund/" target="_blank">Development Fund</a>. For more
      information, check the link: <a href="https://sigchi.org/community/membership/" target="_blank">
        https://sigchi.org/community/membership/ </a>
    </p>
    <p>
      * SIGCHI membership costs $30 for professionals and $10 for students. For more info, visit <a
        href="https://sigchi.org" target="_blank">sigchi.org</a>.
    </p>
  </main>
</template>

<script setup>
</script>

<style scoped>

.table_component {
  overflow: auto;
  width: 100%;
}

.table_component table {
  border: 1px solid var(--background-alt);
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  text-align: left;
}

.table_component caption {
  caption-side: top;
  text-align: left;
}

.table_component th {
  border: 1px solid var(--background-alt);
  background-color: var(--foreground);
  color: var(--text);
  padding: 5px;
}

.table_component td {
  border: 1px solid var(--background-alt);
  background-color: var(--background);
  color: var(--text);
  padding: 5px;
}

.table_component tr td:first-child {
  width: 100px;
}

.thick-border {
  border-bottom: 5px solid var(--background-alt);
}

/* CSS */
.buttonRegistration {
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #000000;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: flex;
  /*font-family: Inter, sans-serif;*/
  font-size: 18px;
  justify-content: center;
  line-height: 28px;
  max-width: 100%;
  text-decoration: none;
  transition: all .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  min-width: 25%;
  max-width: 50%;
  font-family: 'American Typewriter', serif;
  font-weight: normal;

}

.buttonRegistration:active,
.buttonRegistration:hover {
  outline: 0;
}

.buttonRegistration:hover {
  background-color: #FFFFFF;
  border: 1px solid #0077b6;
  font-weight: bold;
  min-width: 50%;
}

@media (min-width: 768px) {
  .buttonRegistration {
    font-size: 20px;
    min-width: 200px;
    padding: 14px 16px;
  }
}
</style>
