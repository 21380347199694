<template>
<main>
  <h1>About</h1>
  <p>
    The ACM International Conference on Tangible, Embedded and Embodied Interaction (TEI) addresses issues of human-computer interaction, novel tools and technologies, interactive art, and user experience. The work presented at TEI has a strong focus on how computing can bridge atoms and bits into cohesive interactive systems. The intimate size of this single-track conference provides a unique forum for exchanging ideas and presenting innovative work through talks, interactive exhibits, demos, hands-on studios, posters, art installations, and performances.
  </p>
  <p>
    TEI is sponsored by <a href="https://sigchi.org/">ACM SIGCHI</a>, who have <a href="https://archive.sigchi.org/conferences/conference-history/">additional historical data</a> on this conference series on their website.
  </p>
  <h2>TEI steering committee</h2>
  <ul>
    <li><strong>Ali Mazalek</strong>, Toronto Metropolitan University (Canada)</li>
    <li><strong>Alissa Antle</strong>, Simon Fraser University (Canada)</li>
    <li><strong>Audrey Girouard</strong>, Carleton University (Canada)</li>
    <li><strong>Augusto Esteves</strong>, ITI/LARSyS, University of Lisbon (Portugal)</li>
    <li><strong>Bart Hengeveld</strong>, Eindhoven University of Technology (Netherlands)</li>
    <li><strong>Brygg Ullmer</strong>, Clemson University (USA)</li>
    <li><strong>Caroline Hummels</strong>, Eindhoven University of Technology (Netherlands)</li>
    <li><strong>Daniel Saakes</strong>, KAIST (Korea)</li>
    <li><strong>Elise van den Hoven</strong>, University of Technology Sydney (Australia) &amp; TU/e (Netherlands)</li>
    <li><strong>Ellen Yi-Luen Do</strong>, University of Colorado Boulder (USA) &amp; National University of Singapore (Singapore)</li>
    <li><strong>Eva Hornecker</strong>, Bauhaus-Universität Weimar (Germany)</li>
    <li><strong>Hiroshi Ishii</strong>, MIT Media Lab (USA)</li>
    <li><strong>Jelle van Dijk</strong>, University of Twente (Netherlands)</li>
    <li><strong>Lian Loke</strong>, University of Sydney (Australia)</li>
    <li><strong>Lucca Geurts</strong>, KU Leuven (Belgium)</li>
    <li><strong>Martin Kaltenbrunner</strong>, UFG (Austria)</li>
    <li><strong>Orit Shaer</strong>, Wellesley College (USA)</li>
    <li><strong>Paul Strohmeier</strong>, Max Planck Institute for Informatics (Germany)</li>
    <li><strong>Robert Jacob</strong>, Tufts University (USA)</li>
    <li><strong>Sara Nabil</strong>, Queen’s University (Canada)</li>
    <li><strong>Stacey Kuznetsov</strong>, Arizona State University (USA)</li>
    <li><strong>Trevor Hogan</strong>, Munster Technological University (Ireland)</li>
    <li><strong>Ylva Fernaeus</strong>, KTH (Sweden)</li>
  </ul>
  <h2>Past TEI Conference</h2>
  <table class="large-table">
    <tr>
      <th>
        Archive
      </th>
      <th>
        Location
      </th>
      <th>
        Acceptance Rate
      </th>
      <th>
        Attendance
      </th>
      <th>
        Publication
      </th>
    </tr>
    <tbody>
      <tr v-for="row of previousTEI">
        <td>
          <a :href="row.website">{{row.name}}</a>
        </td>
        <td>{{row.location}}</td>
        <td>{{row.acceptance}}</td>
        <td>{{row.attendance}}</td>
        <td>
          <a :href="row.publication" v-if="row.publication.length">Proceedings</a>
          <span v-else>To be Published</span>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="small-table">
    <thead>
      <tr>
        <th>
          Archive<br>
          <span class="small-text">Location</span>
        </th>
        <th>
          Publication<br>
          <span class="small-text">Acceptance rate</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row of previousTEI">
        <td>
          <a :href="row.website">{{row.name}}</a><br>
          <span class="small-text">{{row.location}}</span>
        </td>
        <td>
          <a :href="row.publication" v-if="row.publication.length">Proceedings</a>
          <span v-else>To be Published</span><br>
          <span class="small-text">{{row.acceptance}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</main>
</template>

<script setup>
const previousTEI = [
  {
    name: "TEI'24",
    website: "https://tei.acm.org/2024/",
    location: 'Cork, Ireland',
    acceptance: "37/144=25.7%",
    attendance: "220",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/3623509",
  },
  {
    name: "TEI'23",
    website: "https://tei.acm.org/2023/",
    location: 'Warsaw, Poland',
    acceptance: "20/89=22.5%",
    attendance: "150",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/3569009",
  },
  {
    name: "TEI'22",
    website: "https://tei.acm.org/2022/",
    location: 'Daejeon, South Korea (online)',
    acceptance: "27/115 = 24%",
    attendance: "",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/3490149",
  },
  {
    name: "TEI'21",
    website: "https://tei.acm.org/2021/",
    location: 'Salzburg, Austria (online)',
    acceptance: "40/136 = 29%",
    attendance: "350",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/3430524",
  },
  {
    name: "TEI'20",
    website: "https://tei.acm.org/2020/",
    location: 'Sydney, Australia',
    acceptance: "37/132 = 28%",
    attendance: "146",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/3374920",
  },
  {
    name: "TEI'19",
    website: "https://tei.acm.org/2019/",
    location: 'Tempe, Arizona, USA',
    acceptance: "36/110 = 33%",
    attendance: "187",
    publication: "https://st.sigchi.org/publications/toc/tei-2019.html",
  },
  {
    name: "TEI'18",
    website: "https://tei.acm.org/2018/",
    location: 'Stockholm, Sweden',
    acceptance: "37/130 = 28%",
    attendance: "256",
    publication: "https://st.sigchi.org/publications/toc/tei-2018.html",
  },
  {
    name: "TEI'17",
    website: "https://tei.acm.org/2017/",
    location: 'Yokohama, Japan',
    acceptance: "41/151 = 27%",
    attendance: "246",
    publication: "https://st.sigchi.org/publications/toc/tei-2017.html",
  },
  {
    name: "TEI'16",
    website: "https://tei.acm.org/2016/",
    location: 'Eindhoven, Netherlands',
    acceptance: "45/178 = 25%",
    attendance: "332",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/2839462#prox",
  },
  {
    name: "TEI'15",
    website: "https://tei.acm.org/2015/",
    location: 'Stanford, CA, USA',
    acceptance: "63/222 = 28%",
    attendance: "363",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/2677199#prox",
  },
  {
    name: "TEI'14",
    website: "https://tei.acm.org/2014/",
    location: 'Munich, DE',
    acceptance: "46/172 = 27%",
    attendance: "317",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/2540930#prox",
  },
  {
    name: "TEI'13",
    website: "https://tei.acm.org/2013/",
    location: 'Barcelona, ES',
    acceptance: "48/136 = 35%",
    attendance: "205",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/2460625#prox",
  },
  {
    name: "TEI'12",
    website: "https://tei.acm.org/2012/",
    location: 'Kingston, ON, CA',
    acceptance: "42/135 = 31%",
    attendance: "209",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/2148131#prox",
  },
  {
    name: "TEI'11",
    website: "https://tei.acm.org/2011/",
    location: 'Funchal, PT',
    acceptance: "65/203 = 32%",
    attendance: "270",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/1935701#prox",
  },
  {
    name: "TEI'10",
    website: "https://tei.acm.org/2010/",
    location: 'Cambridge, MA, USA',
    acceptance: "54/160 = 34%",
    attendance: "",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/1709886#prox",
  },
  {
    name: "TEI'09",
    website: "https://tei.acm.org/2009/",
    location: 'Cambridge, England, UK',
    acceptance: "70/160 = 44%",
    attendance: "",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/1517664#prox",
  },
  {
    name: "TEI'08",
    website: "https://tei.acm.org/2008/",
    location: 'Bonn, DE',
    acceptance: "46/85 = 54%",
    attendance: "140",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/1347390#prox",
  },
  {
    name: "TEI'07",
    website: "https://tei.acm.org/2007/",
    location: 'Baton Rouge, LA, USA',
    acceptance: "50/93 = 54%",
    attendance: "",
    publication: "https://dl.acm.org/doi/proceedings/10.1145/1347390#prox",
  }
]

console.log("For future webmasters, here is the data array for the past tei table:")
console.log(previousTEI)
</script>

<style scoped>


.small-text {
  font-size: xx-small;
  position: relative;
  top: -10px;
}

.large-table {
  display: none;
}

.large-table tr, .large-table th {
  height: 50px;
}

@media (min-width: 1000px) {
  .large-table {
    display: table;
  }

  .small-table {
    display: none;
  }
}
</style>
