<template>
<main>
  <h1>Program</h1>
  <body>
<!--  <h1>TIME TABLE</h1>-->
<p style="text-align: center"><strong>This program is still provisional and some changes may occur. Particularly, starting and end times of sessions may change.</strong></p>
  <table>
    <tr>
      <th></th>
      <th>Tuesday, 4th</th>
      <th>Wednesday, 5th</th>
      <th>Thursday, 6th</th>
      <th>Friday, 7th</th>
<!--      <th>I<br>9:30-10:20</th>-->
<!--      <th>II<br>10:20-11:10</th>-->
<!--      <th>III<br>11:10-12:00</th>-->
<!--      <th>12:00-12:40</th>-->
<!--      <th>IV<br>12:40-1:30</th>-->
<!--      <th>V<br>1:30-2:20</th>-->
<!--      <th>VI<br>2:20-3:10</th>-->
<!--      <th>VII<br>3:10-4:00</th>-->
    </tr>
    <tr>
      <td class="highlight"><b>8:00</b></td>
      <td colspan="4" class="special">Registration</td>
    </tr>
    <tr>
      <td class="highlight"><b>8:30–10:00</b></td>
      <td>Workshop and GSC session 1</td>
      <td>Opening & <a href="https://tei.acm.org/2025/program/keynote_speaker">Keynote</a></td>
      <td>Papers & Pictorials 4</td>
      <td>Papers & Pictorials 7</td>
<!--      <td class="special">SPORTS</td>-->
    </tr>
    <tr>
<!--      <td class="highlight"><b>10:00–10:15</b></td>-->
<!--      <td></td>-->
<!--      <td>Lightning talk posters</td>-->
<!--      <td>Lightning talk posters</td>-->
<!--      <td>Lightning talk posters</td>-->
    </tr>
    <tr>
      <td class="highlight"><b>10:15–11:00</b></td>
      <td colspan="4" class="special">Coffee Break</td>
    </tr>
    <tr>
      <td class="highlight"><b>11:00–12:30</b></td>
      <td>Workshop and GSC session 2</td>
      <td>Papers & Pictorials 1</td>
      <td>Papers & Pictorials 5</td>
      <td>Papers & Pictorials 8</td>
      <!--      <td class="special">SPORTS</td>-->
    </tr>
    <tr>
      <td class="highlight"><b>12:30–14:00</b></td>
      <td colspan="4" class="special">Lunch</td>
    </tr>
    <tr>
      <td class="highlight"><b>14:00–15:30</b></td>
      <td>Workshop and GSC session 3</td>
      <td>Papers & Pictorials 2</td>
      <td>Papers & Pictorials 6</td>
      <td>Papers & Pictorials 9 + Townhall meeting</td>
    </tr>
    <tr>
      <td class="highlight"><b>15:30–16:00</b></td>
      <td colspan="4" class="special">Coffee Break</td>
    </tr>
    <tr>
      <td class="highlight"><b>16:00–17:30</b></td>
      <td>Workshop and GSC session 4</td>
      <td>Papers & Pictorials 3</td>
      <td>no plenary session (demo and art track setup @CAPC)</td>
      <td>Closing Panel</td>
    </tr>

    <tr>
      <td class="special"> </td>
      <td  class="special">18:00–20:00<br> Welcome reception in <a href="https://maps.app.goo.gl/BjFkPvo71HWBfjpbA" target="_blank">City Townhall of Bordeaux </a></td>
      <td  class="special"></td>
      <td class="special">19:00–00:00<br> Demo and art track reception in <a href="https://maps.app.goo.gl/pUbiipqARyHoPDde8" target="_blank">CAPC (Museum of Contemporary Art)</a></td>
      <td class="special"></td>

    </tr>
  </table>
  </body>
</main>
</template>
<!--<script type="module" src="../../populateCalendar.js"></script>-->
<script setup>

</script>

<style scoped>
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

table {
  border-collapse: collapse;
  margin: 20px auto;
  background-color: var(--background);
  border: 2px solid var(--textlight);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

th,
td {
  border: 1px solid var(--textlight);;
  padding: 10px;
  text-align: center;
}

td {
  background-color: var(--background);
}

th {
  background-color: var(--background-alt);
  color: var(--text);
}

.highlight {
  background-color: var(--foreground);
}

.special {
  background-color: var(--foreground);
  font-weight: bold;

}

</style>
