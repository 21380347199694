<script setup>

</script>

<template>
  <main>
    <h1>Visa Information</h1>

    <p>Unsure if you need a visa to enter France? Please check the <a
        href="https://france-visas.gouv.fr/en/visa-wizard">information provided by the French government</a>.</p>
    <p>ACM is able to provide visa support letters to TEI’25 attendees as well as accepted authors and presenters.
    </p>
    <p>ACM and TEI’25 organizers can provide visa support letters to TEI’25 attendees, accepted authors and presenters.
    </p>
    <p>We provide several ways to get visa letters. Please choose one of them and do not overwhelm the system by doing
      all.
      Importantly, as soon as registration is open, please register immediately for the conference.
    </p>
    <h2>Solution 1. From ACM</h2>
    <p>As a part of the registration workflow, you will be given instructions on how to request the visa letter to be
      issued by ACM. If you missed this step during registration, you can still request a visa support letter via email
      to <a href="mailto:supportletters@acm.org"><b>supportletters@acm.org</b></a>.&nbsp;
    </p>
    <p>The information below should be included with the request:</p>
    <ul>
      <li aria-level="1">Your name as it appears on your passport.</li>
      <li aria-level="1">Your current postal mailing address.</li>
      <li aria-level="1">The name of the conference you are registering for (TEI’25).&nbsp;
      </li>
      <li aria-level="1">Your registration confirmation number.
      </li>
      <li aria-level="1">If you have any papers accepted for the conference, please provide the title and indicate
        whether you are the “sole author” or a “co-author.”
      </li>
      <li aria-level="1">Authors may indicate their paper title. If no paper, speakers can provide the title of their
        presentation/demo.
      </li>
    </ul>
    <p>Please allow up to 10 business days to receive a letter.&nbsp;All requests are handled in the order they are
      received.&nbsp;</p>
    <h2>Solution 2. From TEI organizers</h2>
    <p>Request a visa support letter issued by the TEI’25 organizers by either:<br/>
      a. Filling the TEI'25 visa support letter <a href="https://forms.gle/w8j4edeMr5WusPQv6"> request form</a> (faster).<br/>
      b. Sending an email to <a href="mailto:general_chairs@tei.acm.org">general_chairs@tei.acm.org</a> (takes longer).
      The information below should be included within the email:
      <br/></p>
    <ul>
      <li aria-level="1">Your name as it appears on your passport in the Latin alphabet.</li>
      <li aria-level="1">Your gender and your gender marker as they appear on your passport (This is necessary for the
        French version of the
        letter. For this official document, the process will be faster if the gender used in the letter matches the one
        in the passport).
      </li>
      <li aria-level="1">Your nationality as it appears on your passport in the Latin alphabet.
      </li>
      <li aria-level="1">Your passport number.
      </li>
      <li aria-level="1">Your position (e.g., PhD student, Professor, etc.)
      </li>
      <li aria-level="1">Your affiliation (e.g., University of Bordeaux)
      </li>
      <li aria-level="1">Your affiliation (e.g., University of Bordeaux)
      </li>
      <li aria-level="1">If you have any publications accepted for the conference, please provide both the publication
        type (e.g., paper, pictorial, work-in-progress, etc.) and the publication title.
      </li>
    </ul>
    Please allow up to 10 business days to receive a letter. All requests are handled in the order they are received.
  </main>
</template>

<style scoped>

</style>
